import { useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import { useAuth } from "../../context/auth.context";
import { IonPage, useIonRouter } from "@ionic/react";

const LogoutPage = function () {
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const { authUser, signOut, isLoading } = useAuth();

  const nav = useIonRouter();

  useEffect(() => {
    const signout = async () => {
      if (authUser && !isLoading) {
        await signOut();
      } else if (!authUser && !isLoading) {
        nav.push("/");
      }
    };

    signout().catch((err) => {
      setErrorMessage(err["message"]);
      throw err;
    });
  }, [authUser, isLoading]);

  return (
    <IonPage>
      <div className="flex min-h-screen flex-col items-center justify-center py-16">
        <h1 className="mb-6 text-xl font-bold dark:text-white md:text-5xl">
          Logging out...
        </h1>
        <Spinner
          color={"success"}
          aria-label="Logging out. Please wait."
          size={"xl"}
        />
        <p className="text-red-600 font-bold">{errorMessage}</p>
      </div>
    </IonPage>
  );
};

export default LogoutPage;
