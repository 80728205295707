import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { Controller, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";

import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Spinner } from "flowbite-react";
import { calendarOutline, closeOutline, timeOutline } from "ionicons/icons";
import { DateTime } from "luxon";
import PlacementService from "../../services/placement.service";
import { Placement } from "../../models/placement";

const EditPlacementButtonAndModal: FC<{
  placementId: string | undefined;
  experienceId: string;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonCopy?: string;
}> = ({
  placementId,
  experienceId,
  setErrorData,
  setErrorModalIsOpen,
  buttonCopy,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      experienceId: null,
      placementName: null,
      placementDescription: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      if (placementId) {
        firstValueFrom(PlacementService.getOne(placementId))
          .then((set) => {
            updateFormValues(set);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);

            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newPlacement: Partial<Placement> = {
          id: PlacementService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
          experienceId: experienceId,
        };
        updateFormValues(newPlacement);
        setIsLoading(false);
      }
    }
  }, [isOpen, placementId]);

  function updateFormValues(placement: Partial<Placement>) {
    setValue("id", placement.id);
    setValue("tenantId", placement.tenantId);
    setValue("brandId", placement.brandId);
    setValue("experienceId", placement.experienceId);
    setValue("placementName", placement.placementName || null);
    setValue("placementDescription", placement.placementDescription || null);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await PlacementService.saveOne(formData, placementId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {placementId ? (
        <IonButton size="small" color="warning" onClick={() => setIsOpen(true)}>
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Placement"}
        </IonButton>
      ) : (
        <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Placement"}
        </IonButton>
      )}
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {placementId ? "Edit Placement" : "New Placement"}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("placementName")}
                    type="text"
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Placement name{" "}
                      <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    rows={4}
                    {...register("placementDescription")}
                    labelPlacement="floating"
                  >
                    <div slot="label">Placement Description</div>
                  </IonTextarea>
                </IonItem>
              </IonList>
              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditPlacementButtonAndModal;
