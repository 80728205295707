/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Card, Spinner, Table } from "flowbite-react";
import { useEffect, type FC, useState } from "react";

import { combineLatest, firstValueFrom, map, switchMap } from "rxjs";
import ErrorModal from "../../components/modals/error-modal";
import { useBrandContext } from "../../context/brand.context";
import { Group } from "../../models/group";
import EventService from "../../services/event.service";
import { Event } from "../../models/event";
import { useParams } from "react-router";
import ExperienceService from "../../services/experience.service";
import { Experience } from "../../models/experience";
import GroupService from "../../services/group.service";
import EditExperienceButtonAndModal from "../../components/modals/edit-experience-button-and-modal";
import DataTransformationService from "../../services/data-transformation.service";
import { Placement } from "../../models/placement";
import PlacementService from "../../services/placement.service";
import TestAccessService from "../../services/test-access.service";
import { auth } from "../../firebase/firebase";
import { Tenant } from "../../models/tenant";
import TenantService from "../../services/tenant.service";
import {
  HiChevronDoubleRight,
  HiDownload,
  HiOutlineExternalLink,
  HiPencilAlt,
  HiPlus,
  HiTrash,
  HiX,
} from "react-icons/hi";
import SetService from "../../services/set.service";
import { Set } from "../../models/set";
import { Brand } from "../../models/brand";
import StoreService from "../../services/store.service";
import { Store } from "../../models/store";
import { QRCode } from "react-qrcode-logo";
import ReactPlayer from "react-player";
import SelectSetButtonAndModal from "../../components/modals/select-set-button-and-modal";
import SelectProductButtonAndModal from "../../components/modals/select-product-button-and-modal";
import SelectVideoButtonAndModal from "../../components/modals/select-video-button-and-modal";
import ExperienceProductService from "../../services/experience-product.service";
import ExperienceVideoService from "../../services/experience-video.service";
import ExperienceQuestionService from "../../services/experience-question.service";
import { ExperienceProduct } from "../../models/experience-product";
import { ExperienceVideo } from "../../models/experience-video";
import { ExperienceQuestion } from "../../models/experience-question";
import { Question } from "../../models/question";
import { Video } from "../../models/video";
import { Product } from "../../models/product";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
} from "@ionic/react";
import SpinnerCentered from "../../components/SpinnerCentered";
import Breadcrumbs from "../../components/Breadcrumbs";
import { TestAccess } from "../../models/test-access";
import EditTestAccessButtonAndModal from "../../components/modals/edit-test-access-button-and-modal";
import { closeOutline } from "ionicons/icons";
import ProductVariantService from "../../services/product-variant.service";
import EditPlacementButtonAndModal from "../../components/modals/edit-placement-button-and-modal copy";

const ExperiencePage: FC = function () {
  const { experienceId } = useParams<{ experienceId: string }>();
  const brand: Brand = useBrandContext();

  // Error Message Handling
  const [errorData, setErrorData] = useState<Error | undefined>(undefined);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [experience, setExperience] = useState<Experience>(undefined);
  const [tenant, setTenant] = useState<Tenant>(undefined);
  const [stores, setStores] = useState<Store[]>(undefined);
  const [event, setEvent] = useState<Event>(undefined);
  const [group, setGroup] = useState<Group>(undefined);
  const [placements, setPlacements] = useState<Placement[]>(undefined);
  const [testAccesses, setTestAccesses] = useState<TestAccess[]>(undefined);
  const [experienceProducts, setExperienceProducts] =
    useState<ExperienceProduct[]>(undefined);
  const [experienceVideos, setExperienceVideos] =
    useState<ExperienceVideo[]>(undefined);
  const [experienceQuestions, setExperienceQuestions] =
    useState<ExperienceQuestion[]>(undefined);
  const [set, setSet] = useState<Set>(undefined);

  useEffect(() => {
    const subscription = combineLatest([
      ExperienceService.getOne(experienceId),
      TenantService.getOne(auth.tenantId),
      StoreService.getAllByBrand(auth.tenantId, brand.id),
    ])
      .pipe(
        switchMap(([experience, tenant, stores]) => {
          return combineLatest([
            EventService.getOne(experience.eventId),
            GroupService.getOne(experience.groupId),
            PlacementService.getAllByExperience(
              auth.tenantId,
              brand.id,
              experienceId
            ),
            TestAccessService.getAllByExperience(
              auth.tenantId,
              brand.id,
              experienceId
            ),
            ExperienceProductService.getAllByExperience(
              auth.tenantId,
              brand.id,
              experienceId
            ),
            ExperienceVideoService.getAllByExperience(
              auth.tenantId,
              brand.id,
              experienceId
            ),
            ExperienceQuestionService.getAllByExperience(
              auth.tenantId,
              brand.id,
              experienceId
            ),
            SetService.getOne(experience.setId),
          ]).pipe(
            map(
              ([
                event,
                group,
                placements,
                testAccesses,
                experienceProducts,
                experienceVideos,
                experienceQuestions,
                set,
              ]) => ({
                experience,
                tenant,
                stores,
                event,
                group,
                placements,
                testAccesses,
                experienceProducts,
                experienceVideos,
                experienceQuestions,
                set,
              })
            )
          );
        })
      )
      .subscribe({
        next: ({
          experience,
          tenant,
          stores,
          event,
          group,
          placements,
          testAccesses,
          experienceProducts,
          experienceVideos,
          experienceQuestions,
          set,
        }) => {
          setExperience(experience);
          setTenant(tenant);
          setStores(stores);
          setEvent(event);
          setGroup(group);
          setPlacements(placements);
          setTestAccesses(testAccesses);
          setExperienceProducts(experienceProducts);
          setExperienceVideos(experienceVideos);
          setExperienceQuestions(experienceQuestions);
          setSet(set);
          setIsLoading(false);
        },
        error: (err) => {
          setIsLoading(false);
          setErrorData(err);
          setErrorModalIsOpen(true);
          throw err;
        },
        complete: () => console.info("complete"),
      });

    return () => subscription.unsubscribe();
  }, []);

  /**
   * Adds a set to the experience.
   *
   * @param setId - The ID of the set to add.
   * @returns A Promise that resolves when the set is successfully added.
   * @throws If an error occurs while adding the set.
   */
  async function addSetToExperience(setId: string) {
    try {
      setIsLoading(true);
      await ExperienceService.saveOne(
        { id: experience.id, setId: setId },
        false
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Adds a product to the experience.
   *
   * @param product - The product to be added.
   * @returns A Promise that resolves when the product is successfully added.
   * @throws An error if there is an issue adding the product.
   */
  async function addProductToExperience(product: Product) {
    try {
      setIsLoading(true);

      const experienceProduct: ExperienceProduct = { ...product };
      delete experienceProduct.id;
      delete experienceProduct.createdDate;
      delete experienceProduct.createdByUserAccountId;
      delete experienceProduct.updatedDate;
      delete experienceProduct.updatedByUserAccountId;
      delete experienceProduct.deleted;
      delete experienceProduct.deletedDate;

      experienceProduct.id = ExperienceProductService.generateId();
      experienceProduct.experienceId = experienceId;
      experienceProduct.productId = product.id;
      experienceProduct.experienceProductOrder = experienceProducts.length;

      experienceProduct.productVariants = await firstValueFrom(
        ProductVariantService.getAllByProduct(
          product.tenantId,
          product.brandId,
          product.id
        )
      );

      await ExperienceProductService.saveOne(experienceProduct, true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Deletes an experience product.
   *
   * @param {string} experienceProductId - The ID of the experience product to delete.
   * @returns {Promise<void>} - A promise that resolves when the experience product is deleted.
   */
  async function deleteExperienceProduct(experienceProductId: string) {
    try {
      setIsLoading(true);

      await ExperienceProductService.deleteOne(experienceProductId);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Adds a video to the experience.
   *
   * @param video - The video to be added.
   * @returns A Promise that resolves when the video is successfully added.
   * @throws An error if there is an issue adding the video.
   */
  async function addVideoToExperience(video: Video) {
    try {
      setIsLoading(true);

      const experienceVideo: ExperienceVideo = { ...video };
      delete experienceVideo.id;
      delete experienceVideo.createdDate;
      delete experienceVideo.createdByUserAccountId;
      delete experienceVideo.updatedDate;
      delete experienceVideo.updatedByUserAccountId;
      delete experienceVideo.deleted;
      delete experienceVideo.deletedDate;

      experienceVideo.id = ExperienceVideoService.generateId();
      experienceVideo.experienceId = experienceId;
      experienceVideo.videoId = video.id;
      experienceVideo.experienceVideoOrder = experienceVideos.length + 1;

      await ExperienceVideoService.saveOne(experienceVideo, true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Deletes an experience video by its ID.
   * @param {string} experienceVideoId - The ID of the experience video to delete.
   * @returns {Promise<void>} - A promise that resolves when the experience video is deleted.
   */
  async function deleteExperienceVideo(experienceVideoId: string) {
    try {
      setIsLoading(true);

      await ExperienceVideoService.deleteOne(experienceVideoId);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Adds an experience question to the experience.
   *
   * @param question The question to add.
   * @returns Promise<void>
   */
  async function addExperienceQuestion(question: Question): Promise<void> {
    try {
      setIsLoading(true);

      const experienceQuestion: ExperienceQuestion = { ...question };
      delete experienceQuestion.id;
      delete experienceQuestion.createdDate;
      delete experienceQuestion.createdByUserAccountId;
      delete experienceQuestion.updatedDate;
      delete experienceQuestion.updatedByUserAccountId;
      delete experienceQuestion.deleted;
      delete experienceQuestion.deletedDate;

      experienceQuestion.id = ExperienceQuestionService.generateId();
      experienceQuestion.experienceId = experienceId;
      experienceQuestion.questionId = question.id;
      experienceQuestion.experienceQuestionOrder =
        experienceQuestions.length + 1;

      await ExperienceQuestionService.saveOne(experienceQuestion, true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  /**
   * Deletes an experience question by its ID.
   * @param {string} experienceQuestionId - The ID of the experience question to delete.
   * @returns {Promise<void>} - A promise that resolves when the experience question is deleted.
   */
  async function deleteExperienceQuestion(
    experienceQuestionId: string
  ): Promise<void> {
    try {
      setIsLoading(true);

      await ExperienceQuestionService.deleteOne(experienceQuestionId);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{experience?.experienceName}</IonTitle>
          <IonButtons slot="end">
            <EditExperienceButtonAndModal
              experienceId={experienceId}
              group={group}
              event={event}
              setErrorData={setErrorData}
              setErrorModalIsOpen={setErrorModalIsOpen}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{experience?.experienceName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {isLoading ? (
          <SpinnerCentered />
        ) : (
          <>
            <Breadcrumbs
              data={[
                { name: "Event Groups", path: `/brand/${brand.id}/groups` },
                {
                  name: `${group?.groupName}: ${event?.eventName}`,
                  path: `/brand/${brand.id}/groups/event/${event?.id}`,
                },
                { name: `${experience?.experienceName}` },
              ]}
            ></Breadcrumbs>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        Scheduling
                        <div className="float-right">
                          <EditExperienceButtonAndModal
                            experienceId={experienceId}
                            group={group}
                            event={event}
                            buttonCopy="Edit"
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {/* Dates */}
                      {experience &&
                        experience.experienceStartDate &&
                        experience.experienceEndDate && (
                          <div className="mb-4">
                            <span className="text-gray-500 dark:text-gray-400">
                              Date:{" "}
                              {DataTransformationService.convertToLuxonFormat(
                                experience.experienceStartDate,
                                "LLLL d, y (h:mm a)"
                              )}{" "}
                              to{" "}
                              {DataTransformationService.convertToLuxonFormat(
                                experience.experienceEndDate,
                                "LLLL d, y, (h:mm a)"
                              )}
                            </span>
                          </div>
                        )}

                      {/* Timecodes */}
                      {experience.timecodeStart && experience.timecodeEnd && (
                        <div className="mb-4">
                          <span className="text-gray-500 dark:text-gray-400">
                            Timecode: {experience.timecodeStart} to{" "}
                            {experience.timecodeEnd}
                          </span>
                        </div>
                      )}
                    </IonCardContent>
                  </IonCard>
                  {/* </IonCol>
                <IonCol> */}
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        Set
                        <div className="float-right">
                          <SelectSetButtonAndModal
                            buttonCopy={
                              experience.setId ? "Change Set" : "Select Set"
                            }
                            selection={(id) => addSetToExperience(id)}
                            setErrorData={setErrorData}
                            setErrorModalIsOpen={setErrorModalIsOpen}
                          />
                        </div>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      {set ? (
                        <Card imgSrc={set.setImageUrl}>
                          <h2>{set.setName}</h2>
                          <p>{set.setDescription}</p>
                        </Card>
                      ) : (
                        <p>
                          No set has been associated with this experience. Click
                          the "Select Set" button to choose one.
                        </p>
                      )}
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol></IonCol>
              </IonRow>
            </IonGrid>

            {/* Set and Products */}
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 m-4">
              <div className="lg:col-span-3">
                <div className="sm:flex w-full mb-4">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                    Products
                  </h2>
                  <div className="inline-block ml-4">
                    <SelectProductButtonAndModal
                      buttonCopy={"Add a Product"}
                      storeIds={event.storeIds}
                      selection={(product) => addProductToExperience(product)}
                      setErrorData={setErrorData}
                      setErrorModalIsOpen={setErrorModalIsOpen}
                    />
                  </div>
                </div>

                <ProductsTable
                  brand={brand}
                  experienceProducts={experienceProducts}
                  stores={stores}
                  removeProductFromExperience={deleteExperienceProduct}
                />
              </div>
            </div>

            {/* Placements */}
            <div className="m-4">
              <div className="sm:flex w-full mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                  Placements
                </h2>
                <div className="inline-block ml-4">
                  <EditPlacementButtonAndModal
                    placementId={undefined}
                    experienceId={experienceId}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
              </div>
              <Placements
                tenant={tenant}
                brand={brand}
                experience={experience}
                placements={placements}
                setErrorData={setErrorData}
                setErrorModalIsOpen={setErrorModalIsOpen}
              ></Placements>
            </div>

            {/* Videos */}
            <div className="m-4">
              <div className="sm:flex w-full mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                  Videos
                </h2>
                <div className="inline-block ml-4">
                  <SelectVideoButtonAndModal
                    buttonCopy={"Add a Video"}
                    selection={(video) => addVideoToExperience(video)}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
              </div>
              <VideoCards videos={experienceVideos}></VideoCards>
            </div>

            {/* Test Access */}
            <div className="m-4">
              <div className="sm:flex w-full mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white inline-block">
                  Test Access
                </h2>
                <div className="inline-block ml-4">
                  <EditTestAccessButtonAndModal
                    testAccessId={undefined}
                    experienceId={experienceId}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
              </div>
              <TestAccessesComponent
                tenant={tenant}
                brand={brand}
                experience={experience}
                testAccesses={testAccesses}
                setErrorData={setErrorData}
                setErrorModalIsOpen={setErrorModalIsOpen}
              ></TestAccessesComponent>
            </div>
          </>
        )}

        <ErrorModal
          errorData={errorData}
          errorModalIsOpen={errorModalIsOpen}
          setErrorModalIsOpen={setErrorModalIsOpen}
        />
      </IonContent>
    </IonPage>
  );
};

const ProductsTable: FC<{
  brand: Brand;
  experienceProducts: ExperienceProduct[];
  stores: Store[];
  removeProductFromExperience: (productId: string) => void;
}> = ({ brand, experienceProducts, stores, removeProductFromExperience }) => {
  function getStoreNameById(storeId: string): string {
    const store = stores.find((store) => store.id === storeId);
    return store.storeName;
  }

  async function handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    try {
      // The `from` and `to` properties contain the index of the item
      // when the drag started and ended, respectively
      console.log("Dragged from index", ev.detail.from, "to", ev.detail.to);

      //reorder
      const itemToMove = experienceProducts.splice(ev.detail.from, 1)[0];
      experienceProducts.splice(ev.detail.to, 0, itemToMove);
      ev.detail.complete();

      //gather all saves
      const savePromises: Promise<Experience>[] = [];
      for (const [index, experienceProduct] of experienceProducts.entries()) {
        savePromises.push(
          ExperienceProductService.saveOne(
            {
              id: experienceProduct.id,
              tenantId: experienceProduct.tenantId,
              experienceProductOrder: index,
            },
            false
          )
        );
      }

      //run all saves
      await Promise.all(savePromises);
    } catch (err) {
      alert(err);
      throw err;
    }
  }

  return (
    <>
      <IonList>
        <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
          {experienceProducts.map((experienceProduct) => (
            <IonItem
              className="hover:bg-gray-100 dark:hover:bg-gray-700"
              key={experienceProduct.id}
            >
              <IonReorder slot="start"></IonReorder>
              <IonThumbnail slot="start">
                {experienceProduct.productImageUrl && (
                  <img
                    src={experienceProduct.productImageUrl}
                    className="aspect-square"
                  />
                )}
              </IonThumbnail>
              <IonLabel>
                <div className="text-base font-semibold text-gray-900 dark:text-white">
                  {/* {experienceProduct.experienceProductOrder}:{" "} */}
                  {getStoreNameById(experienceProduct.storeId)} -{" "}
                  {experienceProduct.productName}
                </div>
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Variants:{" "}
                  {experienceProduct.productVariants &&
                  experienceProduct.productVariants.length
                    ? experienceProduct.productVariants.length
                    : 0}
                </div>
                <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
                  Product ID: {experienceProduct.productId}
                </div>
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {experienceProduct.productDescription?.length > 200
                    ? `${experienceProduct.productDescription.substring(
                        0,
                        200
                      )}...`
                    : experienceProduct.productDescription}
                </div>

                {/* <div className="inline-block m-2">
                  <IonButton
                    size="small"
                    color="primary"
                    routerLink={`/brand/${brand.id}/products/${experienceProduct.id}`}
                    routerDirection="forward"
                  >
                    Manage
                    <HiChevronDoubleRight className="ml-1 h-3 w-3" />
                  </IonButton>
                </div> */}
              </IonLabel>
              <IonButton
                slot="end"
                size="small"
                color="danger"
                fill="clear"
                onClick={() =>
                  removeProductFromExperience(experienceProduct.id)
                }
              >
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>

      {experienceProducts.length === 0 && (
        <div className="m-4">
          <p>
            Add products to your event by clicking the "Add Product" button.
          </p>
        </div>
      )}
    </>
  );
};

const Placements: FC<{
  tenant: Tenant;
  brand: Brand;
  experience: Experience;
  placements: Placement[];
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  tenant,
  brand,
  experience,
  placements,
  setErrorData,
  setErrorModalIsOpen,
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  function saveAsImage(qrcodeId: string) {
    console.log(qrcodeId);
    let canvas1 = document.getElementById(qrcodeId) as HTMLCanvasElement;
    let url = canvas1.toDataURL("image/png");
    let link = document.createElement("a");
    link.download = qrcodeId + ".png";
    link.href = url;
    link.click();
  }

  /**
   * Deletes a test access by its ID.
   * @param {string} placementId - The ID of the test access to delete.
   * @returns {Promise<void>} - A promise that resolves when the test access is deleted.
   */
  async function deletePlacement(placementId: string): Promise<void> {
    try {
      setIsDeleting(true);
      console.log("deleting");

      await PlacementService.deleteOne(placementId);

      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
        {placements.map((placement) => (
          <div className="lg:col-span-1" key={placement.id}>
            <Card className="">
              <h3 className="text-md font-medium">{placement.placementName}</h3>
              <p className="text-xs">{placement.placementDescription}</p>
              <div className="w-full h-full">
                {/* Docs: https://www.npmjs.com/package/react-qrcode-logo */}
                <QRCode
                  size={256}
                  qrStyle="dots"
                  // value={
                  //   "https://vanilla-dot-drawbridgeplatform.uc.r.appspot.com"
                  // }
                  value={PlacementService.getPlacementUrl(
                    tenant,
                    brand,
                    experience,
                    placement
                  )}
                  id={"qrcode-" + placement.id}
                />
              </div>

              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
                <div className="col-span-2">
                  <IonButton
                    size="small"
                    color="primary"
                    href={PlacementService.getPlacementUrl(
                      tenant,
                      brand,
                      experience,
                      placement
                    )}
                    // href={
                    //   "https://vanilla-dot-drawbridgeplatform.uc.r.appspot.com"
                    // }
                    target="_blank"
                    className="w-full"
                  >
                    <HiOutlineExternalLink className="mr-1 h-3 w-3" />
                    Web Link
                  </IonButton>
                </div>
                <div className="col-span-2">
                  <IonButton
                    size="small"
                    color="primary"
                    className="w-full"
                    onClick={() => saveAsImage("qrcode-" + placement.id)}
                  >
                    <HiDownload className="mr-1 h-3 w-3" />
                    Download
                  </IonButton>
                </div>
                <div className="col-span-1">
                  <EditPlacementButtonAndModal
                    placementId={placement.id}
                    experienceId={placement.experienceId}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
                <div className="col-span-1">
                  <IonButton
                    size="small"
                    color="danger"
                    onClick={() => {
                      deletePlacement(placement.id);
                    }}
                  >
                    {isDeleting ? (
                      <IonLoading></IonLoading>
                    ) : (
                      <HiTrash className="mr-1 h-3 w-3" />
                    )}
                    Delete
                  </IonButton>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

const VideoCards: FC<{
  videos: ExperienceVideo[];
}> = ({ videos }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {videos.map((video) => (
          <div className="lg:col-span-1" key={video.id}>
            <Card className="">
              <h3 className="text-md font-medium">{video.videoName}</h3>
              <p className="text-xs">{video.videoDescription}</p>

              {(video.videoStreamUrl || video.videoAssetUrl) && (
                <div className="aspect-video">
                  <ReactPlayer
                    url={video.videoStreamUrl || video.videoAssetUrl}
                    light={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              )}

              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
                <div className="col-span-1">
                  <IonButton size="small" color="warning" className="w-full">
                    <HiPencilAlt className="mr-1 h-3 w-3" />
                    Edit
                  </IonButton>
                </div>
                <div className="col-span-1">
                  <IonButton size="small" color="danger" className="w-full">
                    <HiX className="mr-1 h-3 w-3" />
                    Remove
                  </IonButton>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

const TestAccessesComponent: FC<{
  tenant: Tenant;
  brand: Brand;
  experience: Experience;
  testAccesses: TestAccess[];
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  tenant,
  brand,
  experience,
  testAccesses,
  setErrorData,
  setErrorModalIsOpen,
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  function saveAsImage(qrcodeId: string) {
    console.log(qrcodeId);
    let canvas1 = document.getElementById(qrcodeId) as HTMLCanvasElement;
    let url = canvas1.toDataURL("image/png");
    let link = document.createElement("a");
    link.download = qrcodeId + ".png";
    link.href = url;
    link.click();
  }

  /**
   * Deletes a test access by its ID.
   * @param {string} testAccessId - The ID of the test access to delete.
   * @returns {Promise<void>} - A promise that resolves when the test access is deleted.
   */
  async function deleteTestAccess(testAccessId: string): Promise<void> {
    try {
      setIsDeleting(true);
      console.log("deleting");

      await TestAccessService.deleteOne(testAccessId);

      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
        {testAccesses.map((testAccess) => (
          <div className="lg:col-span-1" key={testAccess.id}>
            <Card className="">
              <h3 className="text-md font-medium">
                {testAccess.testAccessName}
              </h3>
              <p className="text-xs">
                {DataTransformationService.convertToLuxonFormat(
                  testAccess.testAccessStartDate,
                  "LLLL d, y (h:mm a)"
                )}
                {" - "}
                {DataTransformationService.convertToLuxonFormat(
                  testAccess.testAccessEndDate,
                  "LLLL d, y (h:mm a)"
                )}
              </p>
              <div className="w-full h-full">
                {/* Docs: https://www.npmjs.com/package/react-qrcode-logo */}
                <QRCode
                  size={256}
                  qrStyle="dots"
                  // value={
                  //   "https://vanilla-dot-drawbridgeplatform.uc.r.appspot.com"
                  // }
                  value={TestAccessService.getTestAccessUrl(
                    tenant,
                    brand,
                    experience,
                    testAccess
                  )}
                  id={"qrcode-" + testAccess.id}
                />
              </div>

              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
                <div className="col-span-2">
                  <IonButton
                    size="small"
                    color="primary"
                    href={TestAccessService.getTestAccessUrl(
                      tenant,
                      brand,
                      experience,
                      testAccess
                    )}
                    // href={
                    //   "https://vanilla-dot-drawbridgeplatform.uc.r.appspot.com"
                    // }
                    target="_blank"
                    className="w-full"
                  >
                    <HiOutlineExternalLink className="mr-1 h-3 w-3" />
                    Web Link
                  </IonButton>
                </div>
                <div className="col-span-2">
                  <IonButton
                    size="small"
                    color="primary"
                    className="w-full"
                    onClick={() => saveAsImage("qrcode-" + testAccess.id)}
                  >
                    <HiDownload className="mr-1 h-3 w-3" />
                    Download
                  </IonButton>
                </div>
                <div className="col-span-1">
                  <EditTestAccessButtonAndModal
                    testAccessId={testAccess.id}
                    experienceId={testAccess.experienceId}
                    setErrorData={setErrorData}
                    setErrorModalIsOpen={setErrorModalIsOpen}
                  />
                </div>
                <div className="col-span-1">
                  <IonButton
                    size="small"
                    color="danger"
                    onClick={() => {
                      deleteTestAccess(testAccess.id);
                    }}
                  >
                    {isDeleting ? (
                      <IonLoading></IonLoading>
                    ) : (
                      <HiTrash className="mr-1 h-3 w-3" />
                    )}
                    Delete
                  </IonButton>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

export default ExperiencePage;
