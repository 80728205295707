import { onAuthStateChanged, User } from "firebase/auth";
import { Context, createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/firebase";

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const clear = () => {
    setAuthUser(null);
    setIsLoading(false);
  };

  const authStateChanged = async (user: any) => {
    setIsLoading(true);

    if (!user) {
      clear();
      return;
    }

    setAuthUser(user);
    setIsLoading(false);
  };

  const signOut = () => auth.signOut().then(() => clear());

  // Listen for Firebase Auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    isLoading,
    signOut,
  };
}

const AuthUserContext = createContext<{
  authUser: User | null;
  isLoading: boolean;
  signOut: () => Promise<void>;
}>({
  authUser: null,
  isLoading: true,
  signOut: async () => {},
});

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () => useContext<any>(AuthUserContext);
