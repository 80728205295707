import { FC, useEffect, useState } from "react";
import { useBrandContext } from "../../context/brand.context";
import { Controller, useForm } from "react-hook-form";
import { firstValueFrom } from "rxjs";

import { auth } from "../../firebase/firebase";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { TestAccess } from "../../models/test-access";
import TestAccessService from "../../services/test-access.service";
import { Spinner } from "flowbite-react";
import { calendarOutline, closeOutline, timeOutline } from "ionicons/icons";
import { DateTime } from "luxon";

const EditTestAccessButtonAndModal: FC<{
  testAccessId: string | undefined;
  experienceId: string;
  setErrorData: React.Dispatch<React.SetStateAction<Error>>;
  setErrorModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonCopy?: string;
}> = ({
  testAccessId,
  experienceId,
  setErrorData,
  setErrorModalIsOpen,
  buttonCopy,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      id: null,
      tenantId: null,
      brandId: null,
      experienceId: null,
      testAccessName: null,
      testAccessStartDate: null,
      testAccessEndDate: null,
    },
  });
  const brand = useBrandContext();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      if (testAccessId) {
        firstValueFrom(TestAccessService.getOne(testAccessId))
          .then((set) => {
            updateFormValues(set);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);

            setErrorData(err);
            setErrorModalIsOpen(true);
            throw err;
          });
      } else {
        const newTestAccess: Partial<TestAccess> = {
          id: TestAccessService.generateId(),
          tenantId: auth.tenantId,
          brandId: brand.id,
          experienceId: experienceId,
        };
        updateFormValues(newTestAccess);
        setIsLoading(false);
      }
    }
  }, [isOpen, testAccessId]);

  function updateFormValues(testAccess: Partial<TestAccess>) {
    setValue("id", testAccess.id);
    setValue("tenantId", testAccess.tenantId);
    setValue("brandId", testAccess.brandId);
    setValue("experienceId", testAccess.experienceId);
    setValue("testAccessName", testAccess.testAccessName || null);
    setValue("testAccessStartDate", testAccess.testAccessStartDate || null);
    setValue("testAccessEndDate", testAccess.testAccessEndDate || null);
  }

  async function onSubmit(formData: any) {
    try {
      setIsSaving(true);

      await TestAccessService.saveOne(formData, testAccessId ? false : true);

      setIsSaving(false);
      setIsOpen(false);
    } catch (err) {
      setIsSaving(false);
      setErrorData(err);
      setErrorModalIsOpen(true);
      throw err;
    }
  }

  return (
    <>
      {testAccessId ? (
        <IonButton size="small" color="warning" onClick={() => setIsOpen(true)}>
          <HiPencilAlt className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "Edit Test Access"}
        </IonButton>
      ) : (
        <IonButton size="small" color="success" onClick={() => setIsOpen(true)}>
          <HiPlus className="mr-1 h-3 w-3" />
          {buttonCopy ? buttonCopy : "New Test Access"}
        </IonButton>
      )}
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {testAccessId ? "Edit Test Access" : "New Test Access"}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isLoading ? (
            <Spinner color="success" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                <IonItem>
                  <IonInput
                    {...register("testAccessName")}
                    type="text"
                    labelPlacement="floating"
                  >
                    <div slot="label">
                      Test Access Name{" "}
                      <IonText color="danger">(Required)</IonText>
                    </div>
                  </IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel>Test Access Start Date</IonLabel>
                  <Controller
                    control={control}
                    name="testAccessStartDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_testAccessStartDate">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_testAccessStartDate"
                              minuteValues="0,15,30,45"
                              max={DateTime.now().plus({ years: 10 }).toISO()}
                              min={DateTime.now().minus({ years: 10 }).toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>

                          {/* Clear Date Button */}
                          {value && (
                            <IonButton
                              fill="clear"
                              color="danger"
                              onClick={() => onChange(null)}
                              slot="end"
                            >
                              <IonIcon icon={closeOutline} />
                            </IonButton>
                          )}
                        </>
                      );
                    }}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel>Test Access End Date</IonLabel>
                  <Controller
                    control={control}
                    name="testAccessEndDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {/* Date and Time selection buttons */}
                          <IonDatetimeButton datetime="datetime_testAccessEndDate">
                            {!value && (
                              <>
                                <IonIcon
                                  icon={calendarOutline}
                                  slot="date-target"
                                ></IonIcon>
                                <IonIcon
                                  icon={timeOutline}
                                  slot="time-target"
                                ></IonIcon>
                              </>
                            )}
                          </IonDatetimeButton>

                          {/* Date and Time selection pop-over */}
                          <IonPopover keepContentsMounted={true}>
                            <IonDatetime
                              id="datetime_testAccessEndDate"
                              minuteValues="0,15,30,45"
                              max={DateTime.now().plus({ years: 10 }).toISO()}
                              min={DateTime.now().minus({ years: 10 }).toISO()}
                              onIonChange={(e) => onChange(e.detail.value)}
                              value={value}
                            ></IonDatetime>
                          </IonPopover>

                          {/* Clear Date Button */}
                          {value && (
                            <IonButton
                              fill="clear"
                              color="danger"
                              onClick={() => onChange(null)}
                              slot="end"
                            >
                              <IonIcon icon={closeOutline} />
                            </IonButton>
                          )}
                        </>
                      );
                    }}
                  />
                </IonItem>
              </IonList>
              <div className="ion-padding">
                <IonButton type="submit" color="primary" disabled={isSaving}>
                  Save
                  {isSaving && (
                    <span className="ml-2">
                      <Spinner
                        color={"success"}
                        size="sm"
                        aria-label="Saving..."
                      />
                    </span>
                  )}
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default EditTestAccessButtonAndModal;
