/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, Spinner, TextInput } from "flowbite-react";
import { FC, useEffect, useState } from "react";
import { useAuth } from "../../context/auth.context";
import SpinnerCentered from "../../components/SpinnerCentered";
import AuthService from "../../services/auth.service";
import { IonButton, IonPage, useIonRouter } from "@ionic/react";

const SignInPage: FC = function () {
  const nav = useIonRouter();
  const { authUser, isLoading } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  useEffect(() => {
    if (authUser?.isAnonymous) {
      nav.push("/logout", "root", "replace");
    } else if (authUser) {
      nav.push("/tenant", "root", "replace");
    }
  }, [authUser, isLoading]);

  const signIn = async () => {
    try {
      setErrorMessage(null);
      setAttemptingLogin(true);

      await AuthService.attemptEmailLogin(email, password);
      return;
    } catch (err: any) {
      setAttemptingLogin(false);
      setErrorMessage(err["message"]);
      throw err;
    }
  };

  return (
    <IonPage>
      {isLoading && !authUser ? (
        <SpinnerCentered />
      ) : (
        <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
          <a href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
            <img
              alt="Drawbridge&trade; Dashboard Manager"
              src="/drawbridge-assets/drawbridge-dashboard-manager-logo.png"
              className="max-h-10"
            />
          </a>
          <Card
            horizontal
            imgSrc="/drawbridge-assets/hand-with-kitchen.png"
            imgAlt=""
            className="w-full md:max-w-[1024px] md:[&>*]:w-full md:[&>*]:p-16 [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 lg:[&>img]:block"
          >
            <h1 className="mb-3 text-2xl font-bold text-black md:text-3xl">
              Welcome to Drawbridge&trade; Dashboard Manager
            </h1>
            <form>
              <div className="mb-4 flex flex-col gap-y-3">
                <Label htmlFor="email">Your email</Label>
                <TextInput
                  id="email"
                  name="email"
                  placeholder="name@company.com"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6 flex flex-col gap-y-3">
                <Label htmlFor="password">Your password</Label>
                <TextInput
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-6 flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <IonButton onClick={signIn} disabled={attemptingLogin}>
                    Login to your account{" "}
                    {attemptingLogin && (
                      <span className="ml-2">
                        <Spinner
                          color={"success"}
                          size="sm"
                          aria-label="Logging into your account."
                        />
                      </span>
                    )}
                  </IonButton>
                  <p className="text-red-600 font-bold">{errorMessage}</p>
                </div>
                <IonButton
                  size="small"
                  fill="clear"
                  href="/authentication/forgot-password"
                  className="w-1/2 text-right"
                >
                  Lost Password?
                </IonButton>
              </div>
              <div className="mb-6"></div>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                Not registered?&nbsp;
                <a
                  href="/authentication/sign-up"
                  className="text-primary-600 dark:text-primary-300"
                >
                  Create account
                </a>
              </p>
            </form>
          </Card>
        </div>
      )}
    </IonPage>
  );
};

export default SignInPage;
